import React, { useEffect } from "react";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import localforage from "localforage";
import { cartStateStore } from "../../utils/store";

function CartIconComponent() {
  const open = cartStateStore((state) => state.open);
  const setOpen = cartStateStore((state) => state.setOpen);
  const [count, setCount] = React.useState(0);

  const fetchCount = async () => {
    try {
      await localforage.length().then((numberOfKeys) => {
        setCount(numberOfKeys);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // Fetch cart count initially
    fetchCount();

    // Create an interval to periodically update the cart count
    const updateInterval = setInterval(() => {
      fetchCount();
    }, 60000); // Update every minute, adjust as needed

    return () => {
      // Clear the interval when the component unmounts
      clearInterval(updateInterval);
    };
  }, []);

  return (
    <>
      <div className="flow-root">
        <button
          className="flex items-center"
          type="submit"
          onClick={() => setOpen(!open)}
        >
          <ShoppingCartIcon
            className="h-8 w-8 p-1 mr-2 text-gray-700 hover:text-black"
            aria-hidden="true"
          />
          {count > 0 && (
            <span
              className={`pl-2 text-sm pr-2 font-medium ${
                count < 1
                  ? "gray-700 hover:text-black"
                  : "gray-700 hover:text-black"
              } `}
            >
              {count}
            </span>
          )}
          <span className="sr-only">items in cart, view bag</span>
        </button>
      </div>
    </>
  );
}

export default CartIconComponent;
