import React, { useEffect, useState } from "react";
import Image from "next/image";
import { getUrl } from "@aws-amplify/storage";
import { ImageType } from "../../src/types";

export default function CartArtImageComponent({ src, alt }: ImageType) {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    async function fetchImageURL() {
      try {
        const urlObject = await getUrl({
          key: src,
          options: {
            accessLevel: "protected",
          },
        });
        setImageSrc(urlObject.url.toString());
      } catch (error) {
        console.error("Error fetching image URL:", error);
      }
    }
    fetchImageURL();
  }, [src]);

  const handleContextMenu = (event: React.MouseEvent<HTMLImageElement>) => {
    event.preventDefault();
  };

  if (!imageSrc) {
    return <div>Loading...</div>;
  }

  return (
    <Image
      src={imageSrc}
      alt={alt}
      height={94}
      width={94}
      onContextMenu={handleContextMenu}
    />
  );
}
