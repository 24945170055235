/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */

export const KLAVIYO_SIGNUP = process.env.NEXT_PUBLIC_KLAVIYO_SIGNUP;

export const imageLoader = ({ src, width, quality }) =>
  `https://www.raraity.com/_next/image?url=${src}&w=${width}&q=${
    quality || 75
  }`;

export const BRAND_NAME: string = "Raraity";

export const BRAND_TAGLINE: string = "design with intelligence";

export const BRAND_DESCRIPTION: string =
  "Raraity is an AI atelier that translates your creative vision into reality. Our platform uses machine learning and artificial intelligence to deliver wall art that is custom, instant and quality -- design with intelligence.";

export const BRAND_URL: string = "https://www.raraity.com";

export const BRAND_IMAGE: string =
  "https://raraityart55525-prod.s3.us-west-2.amazonaws.com/public/ogimageraraity.png";

export const FAVICON: string =
  "https://raraityart55525-prod.s3.us-west-2.amazonaws.com/public/favicon.ico";

export const HOME_IMAGE: string =
  "https://raraityart55525-prod.s3.us-west-2.amazonaws.com/public/home/hamptons_centered_4_compressed.webp";

export const HOME_IMAGES: Array<string> = [
  "/home/hamptons_centered_4_1.webp",
  "/home/hamptons_centered_4_2.webp",
  "/home/hamptons_centered_4_3.webp",
  "/home/hamptons_centered_4_4.webp",
  "/home/hamptons_centered_4_5.webp",
  "/home/hamptons_centered_4_6.webp",
];

export const HOME_ICON: string = "/Raraity_Primary_Icon_Black.svg";

export const NAV_ICON: string = "/Raraity_Logo_Black.svg";

export const ABOUT_PREFIX: string =
  "https://raraityart55525-prod.s3.us-west-2.amazonaws.com/public/about/";

export const classNames = (...classes: any) =>
  classes.filter(Boolean).join(" ");

export const footerNavigation = {
  navigation: [
    { name: "Home", href: "/" },
    { name: "Create", href: "/create-story" },
    { name: "Gallery", href: "/gallery" },
    { name: "Orders", href: "/orders" },
  ],
  customerService: [
    { name: "Account", href: "/account" },
    { name: "FAQ", href: "/faq" },
    // { name: "Hanging Guide", href: "/hanging" },
    // { name: "Sizing Guide", href: "/sizing" },
    { name: "Shipping & Exchanges", href: "/shipping&exchanges" },
    { name: "Track", href: "/track" },
  ],
  company: [
    { name: "About", href: "/about" },
    { name: "Press", href: "/press" },
    { name: "Contact", href: "/contact" },
    // { name: "Learn", href: "/learn" },
  ],
  legal: [
    { name: "Terms & Conditions", href: "/terms&conditions" },
    { name: "Privacy Policy", href: "/privacy" },
    // { name: "Trade", href: "/trade" },
  ],
  social: [
    {
      name: "Pinterest",
      image: "/pinterest.png",
      href: "https://www.pinterest.com/37lckju6v5xyh140ac4qt04v38cqz9/",
    },
    {
      name: "Instagram",
      image: "/instagram.png",
      href: "https://www.instagram.com/raraitydesign/",
    },
    {
      name: "Threads",
      image: "/threads.png",
      href: "https://www.threads.net/@raraitydesign",
    },
    {
      name: "Tiktok",
      image: "/tiktok.png",
      href: "https://www.tiktok.com/@raraityart",
    },
    {
      name: "Youtube",
      image: "/youtube.png",
      href: "https://www.youtube.com/@Raraity",
    },
  ],
};
